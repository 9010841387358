var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#f4f4f4"},attrs:{"id":"app"}},[_vm._m(0),_c('div',{staticClass:"cson-container"},[_c('div',{staticClass:"company-warp"},[_c('div',{staticClass:"company-warp-head"},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"国 外","name":"External"}}),_c('el-tab-pane',{attrs:{"label":"国 内","name":"Internal"}}),_c('el-tab-pane',{attrs:{"label":"全 部","name":"All"}})],1),[_vm._m(1),_c('ul',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.load),expression:"load"}],staticClass:"company-list clearfix",staticStyle:{"overflow-y":"auto","height":"400px"}},_vm._l((_vm.companies),function(company,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":`/companyDetail?id=${company.id}`}},[_c('div',{staticClass:"company-box company-box-bg1",style:({
                    backgroundImage: `linear-gradient(to right ,rgba(${Math.floor(
                      Math.random() * 256
                    )},${Math.floor(Math.random() * 256)},${Math.floor(
                      Math.random() * 256
                    )},${Math.random()}), rgba(${Math.floor(
                      Math.random() * 256
                    )},${Math.floor(Math.random() * 256)},${Math.floor(
                      Math.random() * 256
                    )},${Math.random()})`,
                  })},[_c('div',{staticClass:"company-box-head"},[_c('span',[_vm._v("No."+_vm._s(company.marketCapitalizationRank))]),_c('span',[_vm._v("$"+_vm._s(company.marketCapitalization))])]),_c('div',{staticClass:"company-box-name"},[_vm._v(_vm._s(company.name))])]),_c('div',{staticClass:"company-box-key"},[_c('div',{staticClass:"item"},[_c('span',{class:[
                        'dot',
                        company.details && company.details.internship
                          ? 'green'
                          : 'warn',
                      ]}),_vm._v("Intern ")]),_c('div',{staticClass:"item"},[_c('span',{class:[
                        'dot',
                        company.details && company.details['new grad']
                          ? 'green'
                          : 'warn',
                      ]}),_vm._v("New Grad ")])])])],1)}),0)],_c('div',{staticClass:"search-rank"},[_c('el-input',{attrs:{"placeholder":"搜索","prefix-icon":"el-icon-search","size":"small"},nativeOn:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getCompany(
                _vm.activeName == 'External'
                  ? false
                  : _vm.activeName == 'Internal'
                  ? true
                  : '',
                _vm.searchKey,
                1,
                true
              )}},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}}),_c('span',{},[_vm._v("市值排行")])],1)],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company-banner"},[_c('div',{staticClass:"company-banner-content"},[_c('div',{staticClass:"company-banner-title"},[_vm._v(" 世界顶级巨头公司，打造属于你的内推资源 ")]),_c('div',{staticClass:"company-banner-text"},[_vm._v(" 北美，国内公司一应俱全，全世界范围目标 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update"},[_vm._v(" 市值排行备注，更新时间"),_c('span',{staticClass:"unit"},[_vm._v("（单位：亿）")])])
}]

export { render, staticRenderFns }