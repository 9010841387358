<template>
  <!-- 公司列表 -->
  <div id="app" style="background: #f4f4f4">
    <div class="company-banner">
      <div class="company-banner-content">
        <div class="company-banner-title">
          世界顶级巨头公司，打造属于你的内推资源
        </div>
        <div class="company-banner-text">
          北美，国内公司一应俱全，全世界范围目标
        </div>
      </div>
    </div>
    <div class="cson-container">
      <div class="company-warp">
        <div class="company-warp-head">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="国 外" name="External"></el-tab-pane>
            <el-tab-pane label="国 内" name="Internal"></el-tab-pane>
            <el-tab-pane label="全 部" name="All"></el-tab-pane>
          </el-tabs>
          <template>
            <div class="update">
              市值排行备注，更新时间<span class="unit">（单位：亿）</span>
            </div>
            <ul
              class="company-list clearfix"
              style="overflow-y: auto; height: 400px"
              v-infinite-scroll="load"
            >
              <li v-for="(company, index) in companies" :key="index">
                <router-link :to="`/companyDetail?id=${company.id}`">
                  <div
                    class="company-box company-box-bg1"
                    :style="{
                      backgroundImage: `linear-gradient(to right ,rgba(${Math.floor(
                        Math.random() * 256
                      )},${Math.floor(Math.random() * 256)},${Math.floor(
                        Math.random() * 256
                      )},${Math.random()}), rgba(${Math.floor(
                        Math.random() * 256
                      )},${Math.floor(Math.random() * 256)},${Math.floor(
                        Math.random() * 256
                      )},${Math.random()})`,
                    }"
                  >
                    <div class="company-box-head">
                      <span>No.{{ company.marketCapitalizationRank }}</span>
                      <span>${{ company.marketCapitalization }}</span>
                    </div>
                    <div class="company-box-name">{{ company.name }}</div>
                  </div>
                  <div class="company-box-key">
                    <div class="item">
                      <span
                        :class="[
                          'dot',
                          company.details && company.details.internship
                            ? 'green'
                            : 'warn',
                        ]"
                      ></span
                      >Intern
                    </div>
                    <div class="item">
                      <span
                        :class="[
                          'dot',
                          company.details && company.details['new grad']
                            ? 'green'
                            : 'warn',
                        ]"
                      ></span
                      >New Grad
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </template>
          <div class="search-rank">
            <el-input
              placeholder="搜索"
              prefix-icon="el-icon-search"
              size="small"
              v-model="searchKey"
              @keypress.native.enter="
                getCompany(
                  activeName == 'External'
                    ? false
                    : activeName == 'Internal'
                    ? true
                    : '',
                  searchKey,
                  1,
                  true
                )
              "
            >
            </el-input>
            <span class="">市值排行</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCompany } from "@/service/company";
export default {
  data() {
    return {
      activeName: "External",
      searchKey: "",
      companies: [],
      currentPage: 1,
      total: 0,
    };
  },
  methods: {
    handleClick(tab) {
      this.currentPage = 1;
      this.companies = [];
      getCompany(
        tab.name == "External" ? false : tab.name == "Internal" ? true : "",
        "",
        1
      ).then((res) => {
        if (res.success) {
          this.companies = res.result.records;
          this.total = res.result.total;
          this.currentPage = 1;
        }
      });
    },
    getCompany(isDomestic, keyWord, current, isSearch) {
      getCompany(isDomestic, keyWord, current).then((res) => {
        if (res.success) {
          this.companies = isSearch
            ? res.result.records
            : this.companies.concat(res.result.records);
          this.total = res.result.total;
        }
      });
    },
    load() {
      if (this.companies.length >= this.total) {
        return;
      }
      getCompany(
        this.activeName == "External"
          ? false
          : this.activeName == "Internal"
          ? true
          : "",
        this.searchKey,
        this.currentPage + 1
      ).then((res) => {
        if (res.success) {
          this.companies = this.companies.concat(res.result.records);
          this.currentPage = this.currentPage + 1;
        }
      });
    },
  },

  mounted() {
    this.getCompany(false, "", 1);
  },
  computed: {},
};
</script>

<style lang="css" scoped>
.company-banner img {
  height: 300px;
}

.company-banner {
  position: relative;
  height: 300px;
  background: url(../../assets/company/bg.png) no-repeat center top;
}

.company-banner-title {
  font-size: 36px;
  color: #fff;
  margin-bottom: 15px;
  white-space: nowrap;
}

.company-banner-text {
  font-size: 21px;
  color: #fff;
}

.company-banner-content {
  text-align: center;
  position: absolute;
  width: 565px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.company-warp {
  padding: 50px 30px;
}

.company-warp-head {
  position: relative;
}

.search-rank {
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: absolute;
  right: 0;
  top: 0;
}

.search-rank /deep/ .el-input__inner {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
  border-radius: 20px;
  width: 240px;
}

.search-rank span {
  font-size: 16px;
  color: #34495e;
  text-align: right;
  min-width: 80px;
}

.company-warp-head .el-tabs /deep/ .el-tabs__item {
  height: 27px;
  line-height: 20px;
  padding: 0 15px;
}

.company-warp-head .el-tabs /deep/ .el-tabs__item.is-top:nth-child(2) {
  padding-left: 0;
}

.company-warp-head .el-tabs /deep/ .el-tabs__item.is-active {
  color: #fa6400;
}

.company-warp-head .el-tabs /deep/ .el-tabs__item:hover {
  color: #fa6400;
}

.company-warp-head .el-tabs /deep/ .el-tabs__active-bar {
  background-color: #fa6400;
}

.company-warp-head .el-tabs /deep/ .el-tabs__header {
  margin-bottom: 25px;
}

.company-warp-head .el-tabs /deep/ .el-tabs__nav-wrap::after {
  display: none;
}

.update {
  text-align: right;
  font-size: 12px;
  color: #000000;
}

.unit {
  opacity: 0.5;
  margin-left: 12px;
}

.company-box {
  padding: 6px 12px;
}

.company-list {
  margin: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 12px;
}

.company-list li {
  width: 20%;
  margin-bottom: 24px;
  margin-right: 6.5%;
  float: left;
  padding: 0px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  max-height: 134px;
}
.company-list li:nth-child(4n) {
  margin-right: 0;
}
.company-box {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.company-box-head {
  font-size: 12px;
  color: #fff;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company-box-name {
  font-size: 30px;
  text-align: center;
  color: #fff;
  opacity: 0.8;
  margin: 0 auto;
  margin-bottom: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  box-shadow: 0px 0px 1px 0px #aeb9cc;
  margin-right: 3px;
}

.company-box-key {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
}

.company-box-key .item {
  display: flex;
  color: #000000;
  font-size: 12px;
  opacity: 0.8;
  align-items: center;
  margin-left: 15px;
}

.green {
  background-color: #6dd400;
}

.warn {
  background-color: #f8c51c;
}

.red {
  background-color: #ff665d;
}

.company-box-bg2 {
  background-image: url(../../assets/company/c-bg2.png);
}

.company-box-bg3 {
  background-image: url(../../assets/company/c-bg3.png);
}

.company-box-bg4 {
  background-image: url(../../assets/company/c-bg4.png);
}

.company-box-bg5 {
  background-image: url(../../assets/company/c-bg5.png);
}

.company-box-bg6 {
  background-image: url(../../assets/company/c-bg6.png);
}

.company-box-bg7 {
  background-image: url(../../assets/company/c-bg7.png);
}

.company-box-bg8 {
  background-image: url(../../assets/company/c-bg8.png);
}

.company-box-bg9 {
  background-image: url(../../assets/company/c-bg9.png);
}

.company-box-bg10 {
  background-image: url(../../assets/company/c-bg10.png);
}

.company-box-bg11 {
  background-image: url(../../assets/company/c-bg11.png);
}
</style>
